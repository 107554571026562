const serverIP = "https://sandboxapi.mycop.church";
// const serverIP = "https://trainingapi.mycop.church";
// const serverIP = "https://chmsapi.mycop.church";
// const serverIP = "http://127.0.0.1:8000";

// const DEFAULT_COUNTRY = "gha";

module.exports = {
  BASE_URL: serverIP + "/",
  API_URL: serverIP + "/api",
  MEDIA_URL: serverIP,
  copyright: `© ${new Date().getFullYear()} The Church of Pentecost – General Headquarters. All Rights Reserved`,
  version: "1.0.0",
  DATE_FORMAT: 'DD/MM/YYYY',
  DATE_FORMAT_SYSTEM: 'YYYY-MM-DD',
  DATE_TIME_FORMAT: 'DD/MM/YYYY HH:mm:ss',
  APP_VERSION: '1.0.0'
};

